// All rights reserved for dakwak.com
// copying of the below without reference is strictly prohibited
import '../stylesheets/landing.css';
import '../stylesheets/features.css';
import '../stylesheets/how_it_works.css';
import * as d3 from 'd3';

var dTEData = {
  "name": "root",
  "children": [
    {
      "name": "en",
      "children": [
        {
          "name": "China",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 22
            }
          ]
        },
        {
          "name": "United States",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 77
            },
            {
              "name": "/home",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/sitemap.xml",
              "children": [

              ],
              "size": 6
            },
            {
              "name": "/users/sign_in",
              "children": [

              ],
              "size": 10
            },
            {
              "name": "/support",
              "children": [

              ],
              "size": 3
            },
            {
              "name": "/contact_us?language=en",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/register",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/contact_us",
              "children": [

              ],
              "size": 6
            },
            {
              "name": "/privacy_policy",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/features",
              "children": [

              ],
              "size": 9
            },
            {
              "name": "/users/password/new",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/pricing/",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/tos",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/pricing?language=en",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/how_it_works",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/pricing",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/about_us",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/register?bob=1",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Netherlands",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 7
            }
          ]
        },
        {
          "name": "Singapore",
          "children": [
            {
              "name": "/users/sign_in",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "United Kingdom",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 3
            }
          ]
        },
        {
          "name": "Brazil",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Germany",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 24
            },
            {
              "name": "/?language=en",
              "children": [

              ],
              "size": 6
            },
            {
              "name": "/privacy_policy?language=en",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/features",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/pricing",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/contact_us",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/about_us",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/privacy_policy",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/users/sign_in",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/how_it_works",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/tos",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/support",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/dashboard",
              "children": [

              ],
              "size": 4
            },
            {
              "name": "/websites/app.dakwak.com/actions.json",
              "children": [

              ],
              "size": 8
            },
            {
              "name": "/dakana",
              "children": [

              ],
              "size": 3
            },
            {
              "name": "/dakana?language=en",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/dashboard?language=en",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/register",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/sitemap.xml",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Spain",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/users/sign_in",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Portugal",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 10
            }
          ]
        },
        {
          "name": "Sweden",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Russia",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 8
            },
            {
              "name": "/how_it_works",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/users/sign_in",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/how_it_works?language=en",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/register?plan_id=59afc596d521d48c4700002d&period=3",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/register?plan_id=59afc6a5d521d48c47000039&period=3",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/tos",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/tos?language=en",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/?Press-Release",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/?Press-Release&language=en",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Kazakhstan",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Ukraine",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/contact_us",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Belgium",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 57
            }
          ]
        },
        {
          "name": "Finland",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/?language=en",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/features",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/how_it_works",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/users/sign_in",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/pricing",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/support",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/about_us",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/contact_us",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/register",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/register?bob=1",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/tos",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/privacy_policy",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/features?language=en",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/how_it_works?language=en",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/users/sign_in?language=en",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/users/password/new",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/pricing?language=en",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/support?language=en",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "India",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 6
            },
            {
              "name": "/sitemap.xml",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/users/sign_in",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Canada",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/home",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Vietnam",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Moldova",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/about_us",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/contact_us",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/register",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/register?bob=1",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Hong Kong",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Italy",
          "children": [
            {
              "name": "/",
              "children": [

              ],
              "size": 3
            }
          ]
        }
      ]
    },
    {
      "name": "ar",
      "children": [
        {
          "name": "United States",
          "children": [
            {
              "name": "/tos?language=ar",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/contact_us?language=ar",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/register?language=ar",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/how_it_works?language=ar",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Finland",
          "children": [
            {
              "name": "/?language=ar",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/features?language=ar",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/how_it_works?language=ar",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/users/sign_in?language=ar",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/pricing?language=ar",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/support?language=ar",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Germany",
          "children": [
            {
              "name": "/dakana",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/websites/app.dakwak.com/actions.json",
              "children": [

              ],
              "size": 10
            },
            {
              "name": "/",
              "children": [

              ],
              "size": 6
            },
            {
              "name": "/?language=ar",
              "children": [

              ],
              "size": 9
            },
            {
              "name": "/privacy_policy",
              "children": [

              ],
              "size": 19
            },
            {
              "name": "/features",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/dashboard?language=ar",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/dashboard",
              "children": [

              ],
              "size": 5
            }
          ]
        },
        {
          "name": "Russia",
          "children": [
            {
              "name": "/how_it_works?language=ar",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/tos?language=ar",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/?Press-Release&language=ar",
              "children": [

              ],
              "size": 2
            }
          ]
        }
      ]
    },
    {
      "name": "ja",
      "children": [
        {
          "name": "United States",
          "children": [
            {
              "name": "/contact_us?language=ja",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/?language=ja",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Finland",
          "children": [
            {
              "name": "/?language=ja",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/features?language=ja",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/how_it_works?language=ja",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/users/sign_in?language=ja",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/pricing?language=ja",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/support?language=ja",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Germany",
          "children": [
            {
              "name": "/?language=ja",
              "children": [

              ],
              "size": 4
            },
            {
              "name": "/dashboard",
              "children": [

              ],
              "size": 8
            },
            {
              "name": "/websites/app.dakwak.com/actions.json",
              "children": [

              ],
              "size": 9
            }
          ]
        },
        {
          "name": "Russia",
          "children": [
            {
              "name": "/how_it_works?language=ja",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/tos?language=ja",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/?Press-Release&language=ja",
              "children": [

              ],
              "size": 1
            }
          ]
        }
      ]
    },
    {
      "name": "fr",
      "children": [
        {
          "name": "United States",
          "children": [
            {
              "name": "/features?language=fr",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Finland",
          "children": [
            {
              "name": "/?language=fr",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/features?language=fr",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/how_it_works?language=fr",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/users/sign_in?language=fr",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/pricing?language=fr",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/support?language=fr",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Germany",
          "children": [
            {
              "name": "/?language=fr",
              "children": [

              ],
              "size": 7
            },
            {
              "name": "/how_it_works",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/",
              "children": [

              ],
              "size": 86
            },
            {
              "name": "/dashboard",
              "children": [

              ],
              "size": 6
            },
            {
              "name": "/websites/app.dakwak.com/actions.json",
              "children": [

              ],
              "size": 7
            },
            {
              "name": "/about_us",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/contact_us",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/pricing",
              "children": [

              ],
              "size": 11
            }
          ]
        },
        {
          "name": "Russia",
          "children": [
            {
              "name": "/how_it_works?language=fr",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/tos?language=fr",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/?Press-Release&language=fr",
              "children": [

              ],
              "size": 1
            }
          ]
        }
      ]
    },
    {
      "name": "zh",
      "children": [
        {
          "name": "United States",
          "children": [
            {
              "name": "/contact_us?language=zh",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Finland",
          "children": [
            {
              "name": "/?language=zh",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/features?language=zh",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/how_it_works?language=zh",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/users/sign_in?language=zh",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/pricing?language=zh",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/support?language=zh",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Germany",
          "children": [
            {
              "name": "/privacy_policy?language=zh",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/dashboard?language=zh",
              "children": [

              ],
              "size": 11
            },
            {
              "name": "/websites/app.dakwak.com/actions.json",
              "children": [

              ],
              "size": 36
            },
            {
              "name": "/",
              "children": [

              ],
              "size": 3
            },
            {
              "name": "/privacy_policy",
              "children": [

              ],
              "size": 3
            },
            {
              "name": "/dashboard",
              "children": [

              ],
              "size": 26
            },
            {
              "name": "/dakana",
              "children": [

              ],
              "size": 2
            }
          ]
        },
        {
          "name": "Russia",
          "children": [
            {
              "name": "/how_it_works?language=zh",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/tos?language=zh",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/?Press-Release&language=zh",
              "children": [

              ],
              "size": 1
            }
          ]
        }
      ]
    },
    {
      "name": "nl",
      "children": [
        {
          "name": "Finland",
          "children": [
            {
              "name": "/?language=nl",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/features?language=nl",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/how_it_works?language=nl",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/users/sign_in?language=nl",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/pricing?language=nl",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/support?language=nl",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "United States",
          "children": [
            {
              "name": "/contact_us?language=nl",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Russia",
          "children": [
            {
              "name": "/how_it_works?language=nl",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/tos?language=nl",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/?Press-Release&language=nl",
              "children": [

              ],
              "size": 3
            }
          ]
        }
      ]
    },
    {
      "name": "de",
      "children": [
        {
          "name": "Finland",
          "children": [
            {
              "name": "/?language=de",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/features?language=de",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/how_it_works?language=de",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/users/sign_in?language=de",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/pricing?language=de",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/support?language=de",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Germany",
          "children": [
            {
              "name": "/?language=de",
              "children": [

              ],
              "size": 12
            },
            {
              "name": "/support",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/privacy_policy",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/",
              "children": [

              ],
              "size": 6
            },
            {
              "name": "/features",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/register?language=de",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/register",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/dakana?language=de",
              "children": [

              ],
              "size": 5
            },
            {
              "name": "/websites/app.dakwak.com/actions.json",
              "children": [

              ],
              "size": 7
            },
            {
              "name": "/dashboard",
              "children": [

              ],
              "size": 3
            },
            {
              "name": "/expired?website_id=sample1.dakwak.com",
              "children": [

              ],
              "size": 4
            },
            {
              "name": "/websites/app.dakwak.com/edit",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/dakana",
              "children": [

              ],
              "size": 2
            }
          ]
        },
        {
          "name": "Russia",
          "children": [
            {
              "name": "/how_it_works?language=de",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/tos?language=de",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/?Press-Release&language=de",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "United States",
          "children": [
            {
              "name": "/?language=de",
              "children": [

              ],
              "size": 1
            }
          ]
        }
      ]
    },
    {
      "name": "hi",
      "children": [
        {
          "name": "Finland",
          "children": [
            {
              "name": "/?language=hi",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/features?language=hi",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/how_it_works?language=hi",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/users/sign_in?language=hi",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/pricing?language=hi",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/support?language=hi",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Germany",
          "children": [
            {
              "name": "/privacy_policy?language=hi",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Russia",
          "children": [
            {
              "name": "/how_it_works?language=hi",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/tos?language=hi",
              "children": [

              ],
              "size": 2
            },
            {
              "name": "/?Press-Release&language=hi",
              "children": [

              ],
              "size": 3
            }
          ]
        }
      ]
    },
    {
      "name": "ru",
      "children": [
        {
          "name": "Finland",
          "children": [
            {
              "name": "/?language=ru",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/features?language=ru",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/how_it_works?language=ru",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/users/sign_in?language=ru",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/pricing?language=ru",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Russia",
          "children": [
            {
              "name": "/how_it_works?language=ru",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/tos?language=ru",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/?Press-Release&language=ru",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Germany",
          "children": [
            {
              "name": "/?language=ru",
              "children": [

              ],
              "size": 12
            }
          ]
        }
      ]
    },
    {
      "name": "tr",
      "children": [
        {
          "name": "Finland",
          "children": [
            {
              "name": "/?language=tr",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/features?language=tr",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/how_it_works?language=tr",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/users/sign_in?language=tr",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/pricing?language=tr",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Russia",
          "children": [
            {
              "name": "/how_it_works?language=tr",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/tos?language=tr",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/?Press-Release&language=tr",
              "children": [

              ],
              "size": 1
            }
          ]
        }
      ]
    },
    {
      "name": "uk",
      "children": [
        {
          "name": "Finland",
          "children": [
            {
              "name": "/?language=uk",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/features?language=uk",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/how_it_works?language=uk",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/users/sign_in?language=uk",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/pricing?language=uk",
              "children": [

              ],
              "size": 1
            }
          ]
        },
        {
          "name": "Russia",
          "children": [
            {
              "name": "/how_it_works?language=uk",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/tos?language=uk",
              "children": [

              ],
              "size": 1
            },
            {
              "name": "/?Press-Release&language=uk",
              "children": [

              ],
              "size": 1
            }
          ]
        }
      ]
    }
  ]
}

function mixColors(color1, color2) {
    let c1 = d3.rgb(color1);
    let c2 = d3.rgb(color2);

    // Average the two colors
    let mixedColor = d3.rgb(
        (c1.r + c2.r) / 2,
        (c1.g + c2.g) / 2,
        (c1.b + c2.b) / 2
    );

    return mixedColor.toString();
}

let themeColors = ["#71beeb", "#FF5733", "#3375FF"];

let mixedColors = [
    mixColors(themeColors[0], themeColors[1]),
    mixColors(themeColors[1], themeColors[2])
];

//let colorPalette = themeColors.concat(mixedColors);
 let colorPalette = d3.quantize(d3.interpolateRainbow, 11);
function textSize(text) {
  let baseSize = 17; // Base font size
  let scaleFactor = 0.6; // Font size decrease factor
  let length = text.length;

  // Decrease font size based on text length
  let fontSize = baseSize - length * scaleFactor;

  // Ensure font size is within a certain range
  if (fontSize < 6) fontSize = 6; // Minimum font size
        if (fontSize > 17) fontSize = 17; // Maximum font size

  return fontSize;
}

function showdTEStats(data){
  var width = 700;
  var height = 700;
  var radius = Math.min(width, height) / 11;

  // Define the color scheme
  //var color = d3.scaleOrdinal(["#FF5733", "#FFBD33", "#DBFF33", "#75FF33", "#33FF57", "#33FFBD", "#33DBFF", "#3375FF", "#5733FF", "#BD33FF"]);
  var color = d3.scaleOrdinal(colorPalette);


  //  var color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, 11));

  var partition = data => {
      const root = d3.hierarchy(data)
        .sum(d => d.size) // Here we compute the hierarchy based on the ownSize
        .sort((a, b) => b.value - a.value);
      return d3.partition()
        .size([2 * Math.PI, root.height + 1])
        (root);
  };


  const root = partition(data);
  root.each(d => d.current = d);

  const svg = d3.select("#dTE_metrics").append("svg")
    .attr("viewBox", [0, 0, width, height])
    .style("font", "10px sans-serif");

  const g = svg.append("g")
    .attr("transform", `translate(${width / 2},${height / 2})`);
  var center_text = svg.append("text")
    .attr("text-anchor", "middle")
    .attr("dominant-baseline", "middle")
    .attr("x", width / 2)  // position the text in the center of the SVG
    .attr("y", height / 2) // position the text in the center of the SVG
    .style("font-size", "11px")
    .style("fill", "#333");

  const arc = d3.arc()
    .startAngle(d => d.x0)
    .endAngle(d => d.x1)
    .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(radius * 1.5)
    .innerRadius(d => d.y0 * radius)
    .outerRadius(d => d.y1 * radius - 1);

  const format = d3.format(",d");

  const path = g.append("g")
    .selectAll("path")
    .data(root.descendants().slice(1))
    .join("path")
    .attr("fill", d => { while (d.depth > 1) d = d.parent; return color(d.data.name); })
    .attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
    .attr("d", arc);

  path.filter(d => d.children)
      .style("cursor", "pointer")
      .on("click", clicked);


  function calculateSize(d) {
      if (d.size !== undefined) {
            return d.size;
          }
      if (!d.children) {
            return 0;
          }
      return d.children.reduce((acc, child) => acc + calculateSize(child), 0);
  }

  path.append("title")
      .text(d => `${d.ancestors().map(d => d.data.name).reverse().join("/")}\n${format(calculateSize(d.data))}`);

  const label = g.append("g")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .style("user-select", "none")
      .selectAll("text")
      .data(root.descendants().slice(1))
      .join("text")
      .attr("dy", "0.35em")
      .attr("fill-opacity", d => +labelVisible(d.current))
      .attr("transform", d => labelTransform(d.current))

      .text(d => d.data.name + ": " + format(calculateSize(d.data)))
      .style("font-size", d => `${textSize(d.data.name)}px`); // Apply the dynamic font size here

  const parent = g.append("circle")
    .datum(root)
    .attr("r", radius)
    .attr("fill", "none")
    .attr("pointer-events", "all")
    .on("click", clicked);
    center_text.text("dakwak.com").style("font-size", "19px");
  function clicked(p) {
    parent.datum(p.parent || root);
    if (p.parent) {
      center_text.text( p.data.name).style("font-size", `${textSize(p.data.name)}px`); // Apply the dynamic font size here
    } else {
      center_text.text("dakwak.com").style("font-size", `19px`);
    }
    root.each(d => d.target = {
      x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
      x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
      y0: Math.max(0, d.y0 - p.depth),
      y1: Math.max(0, d.y1 - p.depth)
    });

    const t = g.transition().duration(750);

    // Transition the data on all arcs, even the ones that aren’t visible,
    path.transition(t)
      .tween("data", d => {
        const i = d3.interpolate(d.current, d.target);
        return t => d.current = i(t);
      })
      .filter(function(d) {
        return +this.getAttribute("fill-opacity") || arcVisible(d.target);
      })
      .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
      .attrTween("d", d => () => arc(d.current));

    label.filter(function(d) {
        return +this.getAttribute("fill-opacity") || labelVisible(d.target);
      }).transition(t)
      .attr("fill-opacity", d => +labelVisible(d.target))
      .attrTween("transform", d => () => labelTransform(d.current));
  }

  function arcVisible(d) {
    return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
  }

  function labelVisible(d) {
    return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
  }

  function labelTransform(d) {
    const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
    const y = (d.y0 + d.y1) / 2 * radius;
    return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
  }

}


function generateRandomCoordinates1(count) {
      const coordinates = [];
      for (let i = 0; i < count; i++) {
        const x = Math.random() * window.innerWidth;
        const y = Math.random() * window.innerHeight;
        coordinates.push({ x, y });
      }
      return coordinates;
    }

    function createBalls1(coordinates) {
      const svg = document.querySelector('.background');
      coordinates.forEach(({ x, y }) => {
        const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        circle.setAttribute('cx', x);
        circle.setAttribute('cy', y);
        circle.setAttribute('r', '0.8');
        circle.setAttribute('fill', '#579bff');
        svg.appendChild(circle);
      });
    }

    function updateBallsPosition1(e) {
      const balls = document.querySelectorAll('.background circle');
      balls.forEach(ball => {
        const cx = parseFloat(ball.getAttribute('cx'));
        const cy = parseFloat(ball.getAttribute('cy'));
        const distance = getDistance(e.clientX, e.clientY, cx, cy);
        const maxDistance = 400; // Adjust the maximum distance to trigger movement

        if (distance < maxDistance) {
          const angle = Math.atan2(e.clientY - cy, e.clientX - cx);
          const speed = (maxDistance - distance) / maxDistance;
          const dx = Math.cos(angle) * speed;
          const dy = Math.sin(angle) * speed;

          const newX = cx + dx;
          const newY = cy + dy;

          if (newX >= 0 && newX <= window.innerWidth && newY >= 0 && newY <= window.innerHeight) {
            ball.setAttribute('cx', newX);
            ball.setAttribute('cy', newY);
          }
        }
      });
    }

    // Generate random coordinates for balls
    const ballCount = 600; // Adjust the number of balls as needed
    const coordinates = generateRandomCoordinates1(ballCount);

    // Create balls on the SVG background
    window.addEventListener("DOMContentLoaded", () => {
      createBalls1(coordinates);
    });

    // Listen for mousemove event to update balls' positions
    document.addEventListener('mousemove', function(event) {
  // Check if the event target is the hero element or its descendant
  if (!event.target.closest('.hero')) {
    // Execute your logic for updating the balls' positions
    updateBallsPosition1(event);
  }

});

let balls;
let lines;
let lineMap = [];


  function generateRandomCoordinates() {
     const coordinates = [];
  for (let i = 0; i < 10; i++) {
    const x = Math.random() * window.innerWidth;
    const y = Math.random() * window.innerHeight;
    coordinates.push({ x, y });
  }
  return coordinates;
}

function createBalls(coordinates) {
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("width", "100%");
  svg.setAttribute("height", "100%");
    coordinates.forEach(({ x, y },index) => {
    const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    circle.setAttribute("cx", x);
    circle.setAttribute("cy", y);
    circle.setAttribute("r", "5");
    circle.setAttribute("fill", "#2196f3"); // Adjust the color as desired
    circle.setAttribute("class", "svg-ball"); // Add class
    circle.setAttribute("id", "ball-" + index); // Add id
    circle.setAttribute("shape-rendering", "geometricPrecision");
    circle.setAttribute("data-speed", Math.random() * 1 + 1);

    svg.appendChild(circle);
  });

  return svg;
}

function createLines(coordinates) {
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("width", "100%");
  svg.setAttribute("height", "100%");
  svg.setAttribute("pointer-events", "all") // <- add this line

  const linesGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");

  for (let i = 0; i < coordinates.length; i++) {
    const connectedIndices = getRandomConnectedIndices(i, coordinates.length);
    const connectedCoordinates = connectedIndices.map(index => coordinates[index]);

    for (let j = 0; j < connectedCoordinates.length; j++) {
      const line = document.createElementNS("http://www.w3.org/2000/svg", "path");
      line.setAttribute("d", generateCurvedLine(coordinates[i], connectedCoordinates[j]));
      line.setAttribute("stroke", "#2196f3"); // Adjust the color as desired
      line.setAttribute("class", `ball-${i} ball-${connectedIndices[j]} svg-line`);
      line.setAttribute("data-start", `ball-${i}`); // Store the start ball id
      line.setAttribute("data-end", `ball-${connectedIndices[j]}`); // Store the end ball id
      //
      line.setAttribute("shape-rendering", "geometricPrecision");
      linesGroup.appendChild(line);
    }
  }

  svg.appendChild(linesGroup);

  return svg;
}

function generateCurvedLine(startPoint, endPoint) {
  const controlPointX = (startPoint.x + endPoint.x) / 2;
  const controlPointY = (startPoint.y + endPoint.y) / 2 - Math.abs(startPoint.x - endPoint.x) / 2;

  return `M${startPoint.x},${startPoint.y} Q${controlPointX},${controlPointY} ${endPoint.x},${endPoint.y}`;
}


function getRandomConnectedIndices(currentIndex, maxIndex) {
  const connectedIndices = [];

  while (connectedIndices.length < 2) {
    const index = Math.floor(Math.random() * maxIndex);
    if (index !== currentIndex && !connectedIndices.includes(index)) {
      connectedIndices.push(index);
    }
  }

  return connectedIndices;
}

function generateRandomCoordinatesNearPoint(point, radius) {
  const coordinates = [];
  for (let i = 0; i < 10; i++) {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * radius;
               console.log(radius);
    const x = point.x + distance * Math.cos(angle);
    const y = point.y + distance * Math.sin(angle);
    coordinates.push({ x, y });
  }
  return coordinates;
}

function debounce(func, delay) {
  let timer;
  return function(...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

function getDistanceSquared(x1, y1, x2, y2) {
  const dx = x2 - x1;
  const dy = y2 - y1;
  return dx * dx + dy * dy;
}

function getDistance(x1, y1, x2, y2) {
  return Math.sqrt(getDistanceSquared(x1, y1, x2, y2));
}

function addBackgroundElements() {

  showdTEStats(dTEData);
  const coordinates = generateRandomCoordinates();
  const balls = createBalls(coordinates);
  const lines = createLines(coordinates);
  lines.setAttribute('id', 'lines');

  const backgroundContainer = document.getElementById('background-container');
  if(backgroundContainer !== null)
  {
    backgroundContainer.appendChild(lines);
    backgroundContainer.appendChild(balls);

    const circles = Array.from(balls.getElementsByClassName('svg-ball'));
    circles.forEach((circle, index) => {
      circle.addEventListener('mouseover', (e) => moveBalls(e, coordinates));
      circle.addEventListener('touchmove', (e) => moveBalls(e, coordinates));
      circle.id = 'ball-' + index;
    });

    function handleMouseMove(e) {
      const linesGroup = lines.querySelectorAll('g > path');

      Array.from(linesGroup).forEach(line => {
        const d = line.getAttribute('d');
        if (d === null) {
          console.error('d attribute is null for line: ', line);
          return;
        }

        const commands = d.split(' ');
        const startCommand = commands[0].replace('M', '').split(',');
        const curveCommand = commands[1].replace('Q', '').split(',');

        const startX = parseFloat(startCommand[0]);
        const startY = parseFloat(startCommand[1]);
        const controlX = parseFloat(curveCommand[0]);
        const controlY = parseFloat(curveCommand[1]);
        const endX = parseFloat(curveCommand[2]);
        const endY = parseFloat(curveCommand[3]);
        const mouseX = e.clientX;
        const mouseY = e.clientY;

        const distance = shortestDistanceToLineSegment(mouseX, mouseY, startX, startY, endX, endY);

        if (distance < 200) {
          const startBallId = line.getAttribute('data-start');
          const endBallId = line.getAttribute('data-end');
          const startBall = document.getElementById(startBallId);
          const endBall = document.getElementById(endBallId);
          moveBalls(e, startBall);
          moveBalls(e, endBall);
        }
      });

      circles.forEach(ball => {
        const ballX = parseFloat(ball.getAttribute('cx'));
        const ballY = parseFloat(ball.getAttribute('cy'));

        if (getDistance(e.clientX, e.clientY, ballX, ballY) < 100) {
          moveBalls({ target: ball, clientX: e.clientX, clientY: e.clientY });
        }
      });
    }

    const handleMouseMoveDebounced = debounce(handleMouseMove, 100);
    backgroundContainer.addEventListener('mousemove', handleMouseMoveDebounced);
    //hooking it here for now
  }
}


function shortestDistanceToLineSegment(x, y, x1, y1, x2, y2) {
  const A = x - x1;
  const B = y - y1;
  const C = x2 - x1;
  const D = y2 - y1;

  const dot = A * C + B * D;
  const len_sq = C * C + D * D;
  let param = -1;
  if (len_sq != 0) param = dot / len_sq;

  let xx, yy;

  if (param < 0) {
    xx = x1;
    yy = y1;
  }
  else if (param > 1) {
    xx = x2;
    yy = y2;
  }
  else {
    xx = x1 + param * C;
    yy = y1 + param * D;
  }

  const dx = x - xx;
  const dy = y - yy;
  return Math.sqrt(dx * dx + dy * dy);
}

function moveConnectedLines(ballId, newX, newY) {
  const connectedLines = document.querySelectorAll(`[data-start="${ballId}"], [data-end="${ballId}"]`);

  connectedLines.forEach(line => {
    const startBallId = line.getAttribute("data-start");
    const endBallId = line.getAttribute("data-end");
    const startBall = document.getElementById(startBallId);
    const endBall = document.getElementById(endBallId);

    const startX = parseFloat(startBall.getAttribute("cx"));
    const startY = parseFloat(startBall.getAttribute("cy"));
    const endX = parseFloat(endBall.getAttribute("cx"));
    const endY = parseFloat(endBall.getAttribute("cy"));

    const controlX = (startX + endX) / 2;
    const controlY = (startY + endY) / 2;

    const d = `M ${startX} ${startY} Q ${controlX} ${controlY}, ${endX} ${endY}`;

    line.setAttribute("d", d);
  });
}



let timeoutId;
const movementFactor = 50;  // Adjust this value as needed


// Function to move the balls
function moveBalls(e,coordinates) {
  const ballId = e.target.id;
  const ball = document.getElementById(ballId);
  const speed = parseFloat(ball.getAttribute("data-speed"));

  function animate(coordinates) {

    const currentX = parseFloat(ball.getAttribute("cx"));
    const currentY = parseFloat(ball.getAttribute("cy"));
    const targetX = e.clientX;
    const targetY = e.clientY;
    const speed = parseFloat(ball.getAttribute("data-speed"));

    const newX = lerp(currentX, targetX, 1 / speed);
    const newY = lerp(currentY, targetY, 1 / speed);

    ball.setAttribute("cx", newX);
    ball.setAttribute("cy", newY);

    moveConnectedLines(ballId, newX, newY);

    requestAnimationFrame(() => animate(coordinates));

  }

  function lerp(start, end, amount) {
    return (1 - amount) * start + amount * end;
    }

    // Start the animation
    animate(coordinates);

  }

  function distanceFromPointToLine(point, lineStart, lineEnd) {
  const { x: x0, y: y0 } = point;
  const { x: x1, y: y1 } = lineStart;
  const { x: x2, y: y2 } = lineEnd;

  const numerator = Math.abs((x2 - x1) * (y1 - y0) - (x1 - x0) * (y2 - y1));
  const denominator = Math.sqrt(Math.pow((x2 - x1), 2) + Math.pow((y2 - y1), 2));

  return numerator / denominator;
}

function moveIfCloseToLine(e) {
  const distanceThreshold = 20; // Set this to whatever distance you want
  const lines = document.querySelectorAll('.svg-line');

  lines.forEach(line => {
    const d = line.getAttribute('d').split(' ').map(str => parseFloat(str));
    const lineStart = { x: d[1], y: d[2] };
    const lineEnd = { x: d[6], y: d[7] };
    const point = { x: e.clientX, y: e.clientY };

    const distance = distanceFromPointToLine(point, lineStart, lineEnd);

    if (distance < distanceThreshold) {
      const startBallId = line.getAttribute('data-start');
      const endBallId = line.getAttribute('data-end');
      const startBall = document.getElementById(startBallId);
      const endBall = document.getElementById(endBallId);

      moveBalls({ target: startBall, clientX: e.clientX, clientY: e.clientY });
      moveBalls({ target: endBall, clientX: e.clientX, clientY: e.clientY });
    }
  });
}

  window.addEventListener("DOMContentLoaded", addBackgroundElements);

